export default {
  currentQuestion: 1,
  total: 26,
  questions: {
    q1: {
      name: 'loan_type',
      label: 'Start your mortgage search here!',
      formType: 'select',
      type: 'select',
      options: [
        { label: 'Home Refinance', value: 'refinance' },
        { label: 'New Home Purchase', value: 'mortgage' },
      ],
      autoNext: true,
    },
    q2: {
      name: 'refinance_purpose',
      label: 'Refinance Purpose',
      formType: 'select',
      type: 'refinance_purpose',
      autoNext: true,
      options: [
        { label: 'Lower Monthly Payment', value: 'lower_monthly_payment' },
        { label: 'Take Cash Out', value: 'take_cash_out' },
        { label: 'Payoff Mortgage Faster', value: 'payoff_mortgage_faster' },
        { label: 'Just Checking Rates', value: 'just_checking_rates' },
      ],
      skipIf: { 1: 'mortgage' },
    },
    q3: {
      name: 'property_type',
      label: 'Type of Property',
      formType: 'select',
      type: 'property_type',
      autoNext: true,
      options: [
        { label: 'Single Family', value: 'single_family' },
        { label: 'Multi Family', value: 'multi_family' },
        { label: 'Townhouse', value: 'townhouse' },
        { label: 'Condo', value: 'condo' },
        { label: 'Manufactured', value: 'manufactured' },
        { label: 'Mobile', value: 'mobile' },
      ],
    },
    q4: {
      name: 'property_use',
      label: 'Property Use',
      formType: 'select',
      type: 'property_use',
      autoNext: true,
      options: [
        { label: 'Primary Residence', value: 'primary_residence' },
        { label: 'Second Home', value: 'second_home' },
        { label: 'Investment Property', value: 'investment_property' },
      ],
    },
    q5: {
      name: 'buying_situation',
      label: 'Home Buying Situation',
      formType: 'select',
      type: 'buying_situation',
      autoNext: true,
      options: [
        { label: 'Under Contract', value: 'under_contract' },
        { label: 'Making Offers', value: 'making_offers' },
        { label: 'Buying in 2-6 months', value: 'buying_two_to_six_months' },
        { label: 'Researching Options', value: 'research' },
      ],
      skipIf: { 1: 'refinance' },
    },
    q6: {
      name: 'address_zip',
      label: 'Property Zip Code',
      formType: 'zip-code',
      type: 'zip-code',
      autoNext: false,
      btnLabel: 'Continue',
      validate: {
        required: true,
        length: 5,
      },
      skipIf: { 1: 'mortgage' },
    },
    q7: {
      name: 'property_state',
      label: 'Property State',
      formType: 'list-select',
      type: 'state',
      autoNext: false,
      btnLabel: 'Continue',
      options: [
        { label: 'Alabama', value: 'AL' },
        { label: 'Alaska', value: 'AK' },
        { label: 'American Samoa', value: 'AS' },
        { label: 'Arizona', value: 'AZ' },
        { label: 'Arkansas', value: 'AR' },
        { label: 'California', value: 'CA' },
        { label: 'Colorado', value: 'CO' },
        { label: 'Connecticut', value: 'CT' },
        { label: 'Delaware', value: 'DE' },
        { label: 'District of Columbia', value: 'DC' },
        { label: 'Federated States of Micronesia', value: 'FM' },
        { label: 'Florida', value: 'FL' },
        { label: 'Georgia', value: 'GA' },
        { label: 'Guam', value: 'GU' },
        { label: 'Hawaii', value: 'HI' },
        { label: 'Idaho', value: 'ID' },
        { label: 'Illinois', value: 'IL' },
        { label: 'Indiana', value: 'IN' },
        { label: 'Iowa', value: 'IA' },
        { label: 'Kansas', value: 'KS' },
        { label: 'Kentucky', value: 'KY' },
        { label: 'Louisiana', value: 'LA' },
        { label: 'Maine', value: 'ME' },
        { label: 'Marshall Islands', value: 'MH' },
        { label: 'Maryland', value: 'MD' },
        { label: 'Massachusetts', value: 'MA' },
        { label: 'Michigan', value: 'MI' },
        { label: 'Minnesota', value: 'MN' },
        { label: 'Mississippi', value: 'MS' },
        { label: 'Missouri', value: 'MO' },
        { label: 'Montana', value: 'MT' },
        { label: 'Nebraska', value: 'NE' },
        { label: 'Nevada', value: 'NV' },
        { label: 'New Hampshire', value: 'NH' },
        { label: 'New Jersey', value: 'NJ' },
        { label: 'New Mexico', value: 'NM' },
        { label: 'New York', value: 'NY' },
        { label: 'North Carolina', value: 'NC' },
        { label: 'North Dakota', value: 'ND' },
        { label: 'Northern Mariana Islands', value: 'MP' },
        { label: 'Ohio', value: 'OH' },
        { label: 'Oklahoma', value: 'OK' },
        { label: 'Oregon', value: 'OR' },
        { label: 'Palau', value: 'PW' },
        { label: 'Pennsylvania', value: 'PA' },
        { label: 'Puerto Rico', value: 'PR' },
        { label: 'Rhode Island', value: 'RI' },
        { label: 'South Carolina', value: 'SC' },
        { label: 'South Dakota', value: 'SD' },
        { label: 'Tennessee', value: 'TN' },
        { label: 'Texas', value: 'TX' },
        { label: 'Utah', value: 'UT' },
        { label: 'Vermont', value: 'VT' },
        { label: 'Virgin Islands', value: 'VI' },
        { label: 'Virginia', value: 'VA' },
        { label: 'Washington', value: 'WA' },
        { label: 'West Virginia', value: 'WV' },
        { label: 'Wisconsin', value: 'WI' },
        { label: 'Wyoming', value: 'WY' },
      ],
      validate: {
        required: true,
      },
      skipIf: { 1: 'refinance' },
    },
    q8: {
      name: 'estimated_property_value',
      label: 'Estimated Property Value',
      formType: 'mortgage-slider',
      type: 'mortgage-slider',
      config: {
        value: 300000,
        from: {
          label: '$100k',
          value: 100000,
        },
        to: {
          label: '$2M+',
          value: 2000000,
        },
        steps: [
          100000,
          105000,
          110000,
          115000,
          120000,
          125000,
          130000,
          135000,
          140000,
          145000,
          150000,
          160000,
          170000,
          180000,
          190000,
          200000,
          225000,
          250000,
          275000,
          300000,
          325000,
          350000,
          375000,
          400000,
          425000,
          450000,
          475000,
          500000,
          550000,
          600000,
          650000,
          700000,
          750000,
          800000,
          850000,
          900000,
          950000,
          1000000,
          1500000,
          2000000,
          2000001,
        ],
      },
      autoNext: false,
      btnLabel: 'Continue',
      skipIf: { 1: 'mortgage' },
    },
    q9: {
      name: 'estimated_mortgage_balance',
      label: 'Estimated Mortgage Balance',
      formType: 'mortgage-slider',
      type: 'mortgage-slider',
      config: {
        slideDefaultValue: 80,
        roundFactor: 10000,
        refValueFrom: 8,
        from: {
          label: '1%',
          value: 1,
        },
        to: {
          label: '100%',
          value: 100,
        },
      },

      autoNext: false,
      btnLabel: 'Continue',
      skipIf: { 1: 'mortgage' },
    },
    q10: {
      name: 'additional_cash',
      label: 'Need Additional Cash?',
      formType: 'additional-cash',
      type: 'additional-cash',
      autoNext: false,
      btnLabel: 'Continue',
      skipIf: { 1: 'mortgage', 9: '100' },
    },

    q11: {
      name: 'estimated_purchase_price',
      label: 'Estimated Purchase Price',
      formType: 'mortgage-slider',
      type: 'mortgage-slider',
      config: {
        value: 300000,
        from: {
          label: '$100k',
          value: 100000,
        },
        to: {
          label: '$2M+',
          value: 2000000,
        },
        steps: [
          100000,
          105000,
          110000,
          115000,
          120000,
          125000,
          130000,
          135000,
          140000,
          145000,
          150000,
          160000,
          170000,
          180000,
          190000,
          200000,
          225000,
          250000,
          275000,
          300000,
          325000,
          350000,
          375000,
          400000,
          425000,
          450000,
          475000,
          500000,
          550000,
          600000,
          650000,
          700000,
          750000,
          800000,
          850000,
          900000,
          950000,
          1000000,
          1500000,
          2000000,
          2000001,
        ],
      },
      autoNext: false,
      btnLabel: 'Continue',
      skipIf: { 1: 'refinance' },
    },

    q12: {
      name: 'estimated_down_payment',
      label: 'Estimated Down Payment',
      formType: 'slider-range',
      type: 'slider-range',
      config: {
        labelWrapper: '{l}% down',
        value: 20,
        from: {
          label: '0%',
          value: 0,
        },
        to: {
          label: '50%',
          value: 50,
          labelWrapper: '{l}% down or more',
        },
      },
      autoNext: false,
      btnLabel: 'Continue',
      skipIf: { 1: 'refinance' },
    },

    q13: {
      name: 'is_first_time_buyer',
      label: 'Are you a first time buyer?',
      formType: 'select',
      type: 'first_time_buyer',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      skipIf: { 1: 'refinance' },
    },

    q14: {
      name: 'has_already_found_new_home',
      label: 'Have you already found your new home?',
      formType: 'select',
      type: 'found_home',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      skipIf: { 1: 'refinance' },
    },

    q15: {
      name: 'is_working_with_realestate_agent',
      label: 'Currently working with a real estate agent yet?',
      formType: 'select',
      type: 'working_with_estate_agent',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      skipIf: { 1: 'refinance' },
    },

    q16: {
      name: 'has_military_service',
      label: 'Active or Previous Military Service?',
      formType: 'select',
      type: 'military_service',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    q17: {
      name: 'is_self_employed',
      label: 'Are You Self-Employed?',
      formType: 'select',
      type: 'self_employed',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    q18: {
      name: 'credit_score_qualitative',
      label: 'Your Credit Profile',
      formType: 'select',
      type: 'credit_profile',
      autoNext: true,
      options: [
        { label: 'Excellent', value: 'excellent' },
        { label: 'Very Good', value: 'very_good' },
        { label: 'Good', value: 'good' },
        { label: 'Fair', value: 'average' },
        { label: 'Poor', value: 'poor' },
      ],
    },
    q19: {
      name: 'is_interested_in_improving_credit',
      label: 'Would you like to improve your credit?',
      formType: 'select',
      type: 'improve_credit',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      skipIf: {
        18: ['excellent_720_850', 'good_660_719', 'average_620_659'],
      },
    },
    q20: {
      name: 'has_credit_card_debt',
      label: 'Do you have credit card debt?',
      formType: 'select',
      type: 'credit_debt',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      skipIf: {
        18: ['excellent_720_850', 'good_660_719', 'average_620_659'],
      },
    },
    q21: {
      name: 'credit_card_debt_amount',
      label: 'How much credit card debt do you want to consolidate?',
      formType: 'select',
      type: 'consolidate_debt',
      formType: 'mortgage-slider',
      type: 'mortgage-slider',
      config: {
        value: 0,
        from: {
          label: '$0',
          value: 0,
        },
        to: {
          label: '$100k+',
          value: 100000,
        },
        steps: [
          0,
          5000,
          10000,
          15000,
          20000,
          25000,
          30000,
          35000,
          40000,
          45000,
          50000,
          75000,
          100000,
          100001,
        ],
      },
      autoNext: false,
      btnLabel: 'Show ',
      skipIf: {
        18: ['excellent_720_850', 'good_660_719', 'average_620_659'],
        20: false,
      },
    },
    q22: {
      name: 'has_bankruptcy_last_three_years',
      label: 'Bankruptcy or Foreclosure in the Last 3 Years?',
      formType: 'select',
      type: 'bankruptcy_3_years',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      skipIf: { 1: 'refinance' },
    },
    q23: {
      type: 'current_address',
      formType: 'level-select',
      label: 'Current Address',
      autoNext: false,
      btnLabel: 'Continue',
      forSubmit: { flatten: true },
      validate: {
        required: true,
      },
      options: [
        {
          name: 'address_street_1',
          id: 1,
          formType: 'text',
          itemLabel: 'Street',
          type: 'text',
          validate: {
            required: true,
          },
        },
        {
          name: 'address_zip',
          id: 2,
          formType: 'zip-code',
          type: 'zip-code',
          itemLabel: 'Zip Code',
          validate: {
            required: true,
            length: 5,
          },
        },
        {
          id: 3,
          name: 'address_city',
          formType: 'text',
          itemLabel: 'City',
          type: 'text',
          validate: {
            required: true,
          },
        },
      ],
      skipIf: { 1: 'refinance', 22: false },
    },
    q24: {
      type: 'name',
      formType: 'level-select',
      label: 'Your Name',
      autoNext: false,
      btnLabel: 'Continue',
      forSubmit: { flatten: true },
      validate: {
        required: true,
      },
      options: [
        {
          name: 'name_first',
          id: 1,
          formType: 'text',
          itemLabel: 'First name',
          validate: {
            required: true,
          },
        },
        {
          name: 'name_last',
          id: 1,
          formType: 'text',
          itemLabel: 'Last name',
          validate: {
            required: true,
          },
        },
      ],
      skipIf: { 1: 'refinance', 22: false },
    },
    q25: {
      name: 'email',
      formType: 'email',
      label: 'Email Address',
      validate: {
        required: true,
        regex: 'email',
      },
      autoNext: false,
      btnLabel: 'Continue',
      skipIf: { 1: 'refinance' },
    },
    q26: {
      name: 'phone',
      formType: 'phone',
      itemLabel: 'Phone Number',
      validate: {
        required: true,
        regex: 'phone',
      },
      autoNext: false,
      btnLabel: 'Continue',
      skipIf: { 1: 'refinance' },
    },
  },
  info: {
    firstname: '',
  },

  answers: {},

  errors: {},
};
