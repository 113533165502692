export default {
  currentQuestion: 1,
  total: 16,
  questions: {
    q1: {
      name: 'is_in_medicare',
      label: 'Are you currently enrolled in Medicare Parts A or B?',
      type: 'is-in-medicare',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    q2: {
      name: 'dob',
      label: 'What is your date of birth?',
      type: 'dob',
      formType: 'birthday',
      autoNext: false,
      placeholder: 'yyyy/mm/dd',
      btnLabel: 'Continue',
      validate: {
        required: true,
        message: 'Please enter a valid date of birth',
      },
    },
    q3: {
      name: 'window',
      label: 'When would you like coverage?',
      type: 'window',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Immediately',
          value: 'immediately',
        },
        {
          label: 'Within 2 months',
          value: 'two_months',
        },
      ],
      skipIf: { 1: true },
    },
    q4: {
      name: 'interests',
      label: 'What benefits are most important to you?',
      type: 'interests',
      formType: 'multi-select',
      autoNext: false,
      btnLabel: 'Continue',
      options: [
        {
          label: 'Emergency Care/Hospitalization',
          name: 'emergency_care_hospitalization',
        },
        {
          label: 'Preventative Care',
          name: 'preventative_care',
        },
        {
          label: 'Dental',
          name: 'dental',
        },
        {
          label: 'Vision',
          name: 'vision',
        },
        {
          label: 'Telehealth',
          name: 'telehealth',
        },
        {
          label: 'Coverage for pre-existing conditions',
          name: 'coverage_for_preexisting_conditions',
        },
      ],
      validate: {
        required: true,
      },
      skipIf: { 1: true },
    },
    q5: {
      name: 'gender',
      label: 'What is your gender?',
      type: 'gender',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Female',
          value: 'female',
        },

        {
          label: 'Male',
          value: 'male',
        },
        {
          label: 'Non-Binary',
          value: 'non-binary',
        },
      ],
    },
    q6: {
      name: 'is_smoker',
      label: 'Have you used Tobacco Products within the last 12 months?',
      type: 'is_smoker',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      skipIf: { 1: true },
    },
    q7: {
      name: 'include_spouse_in_plan',
      label:
        'Are you looking to include your <strong>spouse</strong> in your Health Insurance plan?',
      type: 'include_spouse_in_plan',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      skipIf: { 1: true },
      forSubmit: {
        omit: true,
      },
      notifications: [
        {
          q_to_compare: 6,
          show_if_value_is: 'No',
          message:
            'Important tip: As a non-smoker you may qualify for our lowest rates',
        },
        {
          q_to_compare: 6,
          show_if_value_is: 'Yes',
          message: 'Affordable plans for Tobacco Users',
        },
      ],
    },
    q8: {
      name: 'spouse_gender',
      label: "What is your spouse's gender?",
      type: 'gender',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Female',
          value: 'female',
        },

        {
          label: 'Male',
          value: 'male',
        },
        {
          label: 'Non-Binary',
          value: 'non-binary',
        },
      ],
      skipIf: { 1: true, 7: false },
      forSubmit: { group_as: 'spouse' },
    },
    q9: {
      name: 'spouse_date_of_birth',
      label: "What is your spouse's date of birth??",
      type: 'dob',
      formType: 'birthday',
      autoNext: false,
      placeholder: 'yyyy/mm/dd',
      btnLabel: 'Continue',
      validate: {
        required: true,
        message: 'Please enter a valid date of birth',
      },
      skipIf: { 1: true, 7: false },
      forSubmit: { group_as: 'spouse' },
    },
    q10: {
      name: 'has_spouse_used_tobacco',
      label:
        'Has your spouse used <strong>tobacco products</strong> within the last 12 months?',
      type: 'is_smoker',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      skipIf: { 1: true, 7: false },
      forSubmit: { group_as: 'spouse' },
    },
    q11: {
      name: 'include_children_in_plan',
      label:
        'Are you looking to include your <strong>children</strong> in your Health Insurance plan?',
      type: 'include_children_in_plan',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      skipIf: { 1: true },
      forSubmit: {
        omit: true,
      },
    },
    q12: {
      name: 'children',
      label: 'Add your children below',
      type: 'children',
      formType: 'add-children',
      autoNext: false,
      btnLabel: 'Continue',
      options: [
        {
          name: 'dob',
          label: 'Date of Birth',
          type: 'dob',
          placeholder: 'yyyy/mm/dd',
          id: 'dob',
          formType: 'birthday',
          validate: {
            required: true,
            regex: 'birthdate',
          },
        },
        {
          name: 'gender',
          label: 'Gender',
          id: 'gender',
          type: 'gender',
          formType: 'toggle',
          validate: {
            required: true,
            message: 'Please select gender',
          },
        },
        {
          name: 'is_smoker',
          label: 'Smoker?',
          id: 'is_smoker',
          type: 'is_smoker',
          formType: 'toggle',
        },
      ],
      skipIf: { 1: true, 11: false },
    },
    q13: {
      name: 'zip',
      label: 'What is your zip code?',
      formType: 'zip-code',
      type: 'zip_code',
      autoNext: false,
      btnLabel: 'Continue',
      validate: {
        required: true,
        message: 'Enter a valid zip code',
      },
    },
    q14: {
      name: 'name',
      label: 'What is your name?',
      type: 'name',
      formType: 'level-select',
      autoNext: false,
      btnLabel: 'Continue',
      validate: {
        required: true,
      },
      forSubmit: { flatten: true },
      options: [
        {
          name: 'name_first',
          id: 1,
          formType: 'text',
          itemLabel: 'First Name',
          validate: {
            required: true,
            message: 'First name is required',
          },
        },
        {
          name: 'name_last',
          id: 2,
          formType: 'text',
          itemLabel: 'Last Name',
          validate: {
            required: true,
            message: 'Last name is required',
          },
        },
      ],
    },
    q15: {
      name: 'email',
      label: 'What is your email?',
      formType: 'email',
      autoNext: false,
      btnLabel: 'Continue',
      validate: {
        required: true,
        regex: 'email',
        message: 'Please provide a valid email',
      },
    },
    q16: {
      name: 'mobile_phone',
      label: 'Last step! Your results are ready. Mobile or home phone number.',
      type: 'phone',
      formType: 'phone',
      autoNext: false,
      btnLabel: 'View My Results',
      validate: {
        required: true,
        regex: 'phone',
        message: 'Please enter a valid phone number',
      },
    },
  },
  info: {},
  answers: {},
  errors: {},
};
