export default {
  currentQuestion: 1,
  total: 1,
  questions: {
    q1: {
      name: 'get-quote-only',
      label:
        '<div style="text-align: center;max-width: 420px;margin-left: auto;margin-right: auto;margin-bottom: 2.5rem; margin-top:2rem">Welcome to Wayless Quotes - your one stop shop for amazing deals on auto insurance! <br /> <br /> Click below now to get your quote!</div>',
      formType: 'get-quote-only',
      type: 'zip-code',
      autoNext: false,
      btnLabel: 'Get Quote',
    },
  },
  info: {
    firstname: '',
  },
  answers: {},
  errors: {},
};
