import { Route, Switch, Redirect } from 'react-router-dom';

import DefaultLayout from '@/layout/DefaultLayout';
import HomePage from '@/pages/index';

const renderRoutes = () => {
  const CurrentLayout = DefaultLayout;

  return (
    <CurrentLayout>
      <Switch>
        <Route path="/">
          {/* <Redirect from="/" to="/step-1" /> */}
          <HomePage />
        </Route>
      </Switch>
    </CurrentLayout>
  );
};

export default renderRoutes;
