import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import amplitude from 'amplitude-js';
import funnels from '@/data/funnels';
import { css } from '@emotion/css';
import usePrevious from '@/hooks/usePrevious';

import validateQuestion from './validateQuestion';

import {
  setQuestions,
  setAllAnswers,
  setTotal,
  setAnswer,
  removeAnswer,
  goToNextQuestion,
  goToPreviousQuestion,
  addError,
} from '@/store/slices/formSlice';
import ProgressBar from '@/components/ProgressBar';
import PreviousQuestion from '@/components/PreviousQuestion';
import LoadingSkeleton from '@/components/LoadingSkeleton';
import FormButton from '@/components/FormButton';
import TermsAndConditions from '@/components/TermsAndConditions';
import Notification from '@/components/Notification';
import QuoteSelect from './components/QuoteSelect';
import QuoteStateMedicareSelect from './components/QuoteStateMedicareSelect';
import QuoteMultiSelect from './components/QuoteMultiSelect';
import QuoteLevelSelect from './components/QuoteLevelSelect';
import QuoteFormItem from './components/QuoteFormItem';
import QuoteAddress from './components/QuoteAddress';
import QuoteVehicleModel from './components/QuoteVehicleModel';
import QuoteAddChildren from './components/QuoteAddChildren';
import QuoteSliderRange from './components/QuoteSliderRange';
import QuoteSkip from './components/QuoteSkip';
import QuoteAdditionalCash from './components/QuoteAdditionalCash';
import QuoteMortgageSlider from './components/QuoteMortgageSlider';
import QuoteFormSelect from './components/QuoteFormSelect';

const QuoteForm = ({
  onShowQuotes,
  type,
  changeQuestionDirection = '',
  quotesIsVisible = false,
  onChangeDirectionCallback = null,
  changeQuotesVisibilityCallback = null,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [isTransitioningQuestions, setIsTransitioningQuestions] = useState(
    false
  );
  const [notificationMsg, setNotificationMsg] = useState('');

  const prevDirection = usePrevious(changeQuestionDirection);
  let t2 = null;
  let t3 = null;
  const {
    questions,
    currentQuestion,
    answers,
    total,
    isComplete,
  } = useSelector((store) => store.form);

  const quoteItemsWrapperStyles = css`
    min-height: 50px;
  `;

  const {
    label = null,
    itemLabel = null,
    formType = null,
    options = null,
    otherOptions = null,
    otherOptionsLabel = null,
    autoNext = null,
    placeholder = null,
    btnLabel = null,
    name = null,
    validate = null,
    config = null,
    notifications = null,
  } = questions[`q${currentQuestion}`];

  const nextUrl = () => {
    history.push(`/${history.location.search}`);
  };

  const handleOnChange = (answer, subId) => {
    const answerUpdate = {
      qId: currentQuestion,
    };

    if (subId) {
      answerUpdate.answer = { ...answers[currentQuestion] } || {};
      answerUpdate.answer[subId] = answer;
    } else {
      answerUpdate.answer = answer;
    }

    dispatch(setAnswer(answerUpdate));

    if (autoNext) {
      nextQuestion();
      nextUrl();
    }
  };

  const nextQuestion = () => {
    transitionQuestion('forward');
  };

  const previousQuestion = () => {
    transitionQuestion('previous');
  };

  const transitionQuestion = (direction) => {
    setFadeOut(true);
    setFadeIn(false);

    // Returns the action for the desired direction
    const goTo =
      direction === 'forward'
        ? goToNextQuestion(currentQuestion)
        : goToPreviousQuestion(currentQuestion);

    t2 = setTimeout(() => {
      setIsTransitioningQuestions(true);
      dispatch(goTo);
      t3 = setTimeout(() => setIsTransitioningQuestions(false), 100);
    }, 100);
  };

  const prevClickHandler = (e) => {
    history.goBack();
  };

  const skipHandler = (e) => {
    dispatch(removeAnswer(currentQuestion));
    nextQuestion();
  };

  const validationHandler = () => {
    const [isValid, errors] = validateQuestion(
      type,
      currentQuestion,
      answers,
      validate,
      formType,
      options,
      name
    );
    // If validation returns any errors, dispatch them to redux
    errors.forEach((err, index) => {
      dispatch(addError(err));
    });

    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validationHandler();
    if (isValid) {
      nextQuestion();
      nextUrl();
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !autoNext) {
      handleSubmit();
    }
  };

  const logEventToAmplitude = () => {
    const props = {
      number: currentQuestion,
      name,
    };
    amplitude.getInstance().logEvent('survey_step', props);
  };

  const isGetQuoteOnly = formType === 'get-quote-only';

  useEffect(() => {
    if (
      changeQuestionDirection !== prevDirection &&
      changeQuestionDirection == 'forward' &&
      currentQuestion <= total
    ) {
      nextQuestion();
    }

    if (
      changeQuestionDirection !== prevDirection &&
      changeQuestionDirection == 'backward' &&
      currentQuestion > 1 &&
      !quotesIsVisible
    ) {
      previousQuestion();
    }

    if (quotesIsVisible && changeQuotesVisibilityCallback) {
      changeQuotesVisibilityCallback(false);
    }

    onChangeDirectionCallback();
  }, [changeQuestionDirection]);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEventToAmplitude();
  }, [currentQuestion]);

  useEffect(() => {
    let t = null;
    if (fadeOut) {
      t = setTimeout(() => {
        setFadeIn(true);
        setFadeOut(false);
      }, 500);
    }
    return () => clearTimeout(t);
  }, [fadeOut]);

  useEffect(() => {
    // When the type is set, set the questions for this survey
    if (type && funnels[type]) {
      dispatch(setTotal(funnels[type].total));
      dispatch(setAllAnswers(funnels[type].answers));
      dispatch(setQuestions(funnels[type].questions));
    }
  }, [type]);

  // Add a notification if needed
  useEffect(() => {
    let msg = '';
    // If there's a notification message to show
    if (
      notifications &&
      notifications.some(
        (notif) => notif.show_if_value_is === answers[notif.q_to_compare]
      )
    ) {
      msg = notifications.find(
        (notif) => notif.show_if_value_is === answers[notif.q_to_compare]
      ).message;
    }
    setNotificationMsg(msg);
  }, [notifications]);

  useEffect(() => {
    return () => {
      clearTimeout(t2);
      clearTimeout(t3);
    };
  }, []);

  return (
    <section
      onKeyDown={handleKeyDown}
      role="presentation"
      className="bg-white max-w-5xl p-5 md:p-8 mx-auto w-full"
    >
      {total === 0 ? (
        <LoadingSkeleton />
      ) : (
        <>
          {!isGetQuoteOnly && (
            <div className="mb-4">
              <ProgressBar />
            </div>
          )}

          <div
            className={`${fadeIn ? 'opacity-100' : ''} ${
              fadeOut ? 'opacity-0' : ''
            } opacity-0 transition-all duration-100`}
          >
            <PreviousQuestion onClick={prevClickHandler} />

            {notificationMsg && (
              <div className="w-full mt-2 md:mt-0 text-center">
                <Notification title={notificationMsg} />
              </div>
            )}

            <div className="my-4 sm:mt-10 sm:mb-7 text-lg">
              <span dangerouslySetInnerHTML={{ __html: label }} />
            </div>

            {!isGetQuoteOnly && (
              <div className={quoteItemsWrapperStyles}>
                {!isTransitioningQuestions && (
                  <>
                    {formType === 'select' && (
                      <QuoteSelect
                        options={options}
                        otherOptions={otherOptions}
                        otherOptionsLabel={otherOptionsLabel}
                        autoNext={autoNext}
                        onSelect={handleOnChange}
                      />
                    )}

                    {formType === 'list-select' && (
                      <QuoteFormSelect
                        options={options}
                        autoNext={autoNext}
                        onClick={handleOnChange}
                      />
                    )}

                    {formType === 'slider-range' && (
                      <QuoteSliderRange
                        config={config}
                        onUpdate={handleOnChange}
                      />
                    )}

                    {formType === 'mortgage-slider' && (
                      <QuoteMortgageSlider
                        config={config}
                        onUpdate={handleOnChange}
                      />
                    )}

                    {formType === 'additional-cash' && (
                      <QuoteAdditionalCash onUpdate={handleOnChange} />
                    )}

                    {formType === 'state-medicare-select' && (
                      <QuoteStateMedicareSelect
                        options={options}
                        otherOptions={otherOptions}
                        otherOptionsLabel={otherOptionsLabel}
                        autoNext={autoNext}
                        onSelect={handleOnChange}
                      />
                    )}

                    {formType === 'multi-select' && (
                      <QuoteMultiSelect
                        options={options}
                        otherOptions={otherOptions}
                        otherOptionsLabel={otherOptionsLabel}
                        autoNext={autoNext}
                        onSelect={handleOnChange}
                      />
                    )}

                    {formType === 'level-select' && (
                      <QuoteLevelSelect
                        options={options}
                        onSelect={handleOnChange}
                      />
                    )}

                    {formType === 'add-children' && (
                      <QuoteAddChildren
                        onSelect={handleOnChange}
                        doValidation={validationHandler}
                        options={options}
                      />
                    )}

                    {formType === 'vehicle-model' && (
                      <QuoteVehicleModel
                        name={name}
                        onSelect={handleOnChange}
                      />
                    )}

                    {formType === 'address' && (
                      <QuoteAddress onSelect={handleOnChange} />
                    )}

                    {[
                      'birthday',
                      'email',
                      'text',
                      'post-code',
                      'zip-code',
                      'phone',
                    ].includes(formType) && (
                      <QuoteFormItem
                        label={itemLabel}
                        formType={formType}
                        onChange={handleOnChange}
                        placeholder={placeholder}
                      />
                    )}
                  </>
                )}
              </div>
            )}
            {total === currentQuestion && type === 'medicare' && (
              <p className="text-xs">
                By clicking the View My Results Button, I agree to the consents
                below the&nbsp;button.
              </p>
            )}

            <section
              className={`flex flex-col md:flex-row mt-6 ${
                isGetQuoteOnly ? 'justify-center mb-5' : 'justify-start'
              }`}
            >
              {!autoNext && (
                <FormButton onClick={handleSubmit} label={btnLabel} />
              )}

              {!autoNext && formType === 'birthday' && (
                <QuoteSkip onClick={skipHandler} />
              )}
            </section>

            {total === currentQuestion && <TermsAndConditions type={type} />}
          </div>
        </>
      )}
    </section>
  );
};

QuoteForm.propTypes = {
  onShowQuotes: PropTypes.func.isRequired,
  quotesIsVisible: PropTypes.bool,
  type: PropTypes.string.isRequired,
  changeQuestionDirection: PropTypes.string,
  onChangeDirectionCallback: PropTypes.func,
  changeQuotesVisibilityCallback: PropTypes.func,
};

export default QuoteForm;
