import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import QuoteFormItem from './QuoteFormItem';
import FormCheckbox from '@/components/FormCheckbox';
import FormToggle from '@/components/FormToggle';
import { css } from '@emotion/css';

const QuoteChild = ({ value, onChange, options, answer, subId = false }) => {
  const wrapperStyle = css`
    width: 100%;
    max-width: 560px;
    margin-bottom: 0.25rem;
  `;

  const birthDateStyles = css`
    max-width: 120px;
    @media only screen and (min-width: 767px) {
      max-width: none;
    }
  `;

  const genderMaleEl = (
    <span>
      M<span className="hidden md:inline">ale</span>
    </span>
  );

  const genderFemaleEl = (
    <span>
      F<span className="hidden md:inline">emale</span>
    </span>
  );

  const updateStateHandler = (newVal, key) => {
    onChange(newVal, key);
  };
  return (
    <div className={wrapperStyle}>
      <div className="flex flex-row">
        {answer &&
          Object.keys(answer).map((key, index) => {
            const {
              formType,
              id,
              name,
              label = '',
              placeholder = '',
            } = options[index];

            if (['birthday'].includes(formType)) {
              return (
                <div
                  className={birthDateStyles}
                  key={'quote-child-ele-' + index}
                >
                  <QuoteFormItem
                    label={label}
                    formType={formType}
                    onChange={(val) => updateStateHandler(val, key)}
                    placeholder={placeholder}
                    subId={key}
                    defaultVal={answer[key]}
                  />
                </div>
              );
            }

            if (name === 'gender') {
              return (
                <div className="mx-4 md:mx-6" key={'quote-child-ele-' + index}>
                  <FormToggle
                    title="Gender"
                    valOne="male"
                    valTwo="female"
                    labelOne={genderMaleEl}
                    labelTwo={genderFemaleEl}
                    onChange={(val) => updateStateHandler(val, key)}
                    value={answer[key]}
                    subId={key}
                  />
                </div>
              );
            }

            if (name === 'is_smoker') {
              return (
                <div
                  className="ml-auto md:ml-2"
                  key={'quote-child-ele-' + index}
                >
                  <p className="text-sm text-gray-800 mb-2">{label}</p>
                  <div className="bg-gray-200 p-3 rounded">
                    <FormCheckbox
                      label="Yes"
                      checked={answer[key]}
                      onChange={(event) =>
                        updateStateHandler(event.target.checked, key)
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

QuoteChild.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  subId: PropTypes.number,
  options: PropTypes.array,
  answer: PropTypes.object,
};

export default QuoteChild;
