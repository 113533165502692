export default {
  currentQuestion: 1,
  total: 1,
  questions: {
    q1: {
      name: 'zip',
      label: 'Enter Zip Code to begin',
      formType: 'zip-code',
      type: 'zip-code',
      autoNext: false,
      btnLabel: 'Check Rates',
      validate: {
        required: true,
        length: 5,
      },
    },
  },
  info: {
    firstname: '',
  },
  answers: {},
  errors: {},
};
