import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormSlider from '@/components/FormSlider';
import { currencyFormat } from '@/utils/helpers';

const QuoteMortgageSlider = ({ config, onUpdate }) => {
  const { from, to, value, step, labelWrapper } = config;
  const [currentValue, setCurrentValue] = useState(value || 0);

  const handleOnChange = (e) => {
    const value = e.target.value;
    setCurrentValue(value);
    onUpdate(currentValue);
  };

  const printedLabel = () => {
    if (currentValue == to.value && to.labelWrapper) {
      return to.labelWrapper.replace('{l}', currentValue);
    }
    if (labelWrapper) {
      return labelWrapper.replace('{l}', currentValue);
    }
    return currencyFormat(currentValue);
  };

  useEffect(() => {
    currentValue && onUpdate(currentValue);
  }, []);

  return (
    <FormSlider
      label={printedLabel()}
      min={from.value}
      step={step}
      max={to.value}
      value={currentValue}
      onChange={handleOnChange}
      fromLabel={from.label}
      toLabel={to.label}
    />
  );
};

QuoteMortgageSlider.propTypes = {
  config: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default QuoteMortgageSlider;
