import { createSlice } from '@reduxjs/toolkit';
import { years, otherYears } from '@/data/years';
import { makes, otherMakes } from '@/data/make';
import model from '@/data/model';
import { getNextQuestion, getPreviousQuestion } from '@/utils/helpers';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    currentQuestion: 1,
    total: 0,
    questions: {
      q1: {
        name: '',
        label: '',
        formType: '',
        type: '',
        autoNext: false,
      },
    },
    isComplete: false,
    info: {},
    answers: {},
    errors: {},
  },
  reducers: {
    addError: (state, { payload }) => {
      const { qId, subId, error } = payload;

      state.errors[qId] = state.errors[qId] || {};

      if (subId) {
        state.errors[qId][subId] = error;
        return;
      }

      state.errors[qId] = error;
    },

    removeError: (state, { payload }) => {
      const { qId, subId } = payload;

      if (subId) {
        delete state.errors[qId][subId];
        return;
      }
      delete state.errors[qId];
    },
    setCurrentQuestion: (state, { payload }) => {
      state.currentQuestion = payload;
    },
    setQuestions: (state, { payload }) => {
      state.questions = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setAllAnswers: (state, { payload }) => {
      state.answers = payload;
    },
    setAnswer: (state, { payload }) => {
      const { qId, answer } = payload;
      state.answers[qId] = answer;

      const currentQuestion = state.questions[`q${qId}`];

      if (currentQuestion.type === 'info_yn') {
        const firstname = state.answers[qId][1];
        if (firstname) {
          state.info.firstname = firstname;
        }
      }
    },
    removeAnswer: (state, { payload }) => {
      if (!state?.answers?.[payload]) return state;
      const answers = { ...state.answers };
      delete answers[payload];
      state.answers = answers;
    },

    goToNextQuestion: (state, { payload }) => {
      const next = getNextQuestion(
        payload,
        state.questions,
        state.answers,
        state.total
      );

      state.isComplete = state.currentQuestion + next > state.total;
      state.currentQuestion = state.currentQuestion + next;
    },

    goToPreviousQuestion: (state) => {
      const previous = getPreviousQuestion(
        state.currentQuestion,
        state.questions,
        state.answers
      );

      state.currentQuestion = state.currentQuestion - previous;
    },
  },
});

export const {
  setQuestions,
  setCurrentQuestion,
  setAllAnswers,
  setTotal,
  setAnswer,
  removeAnswer,
  addError,
  removeError,
  goToNextQuestion,
  goToPreviousQuestion,
} = formSlice.actions;

export default formSlice.reducer;
