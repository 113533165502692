import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormSlider from '@/components/FormSlider';
import { currencyFormat } from '@/utils/helpers';
import { useSelector } from 'react-redux';

const QuoteMortgageSlider = ({ config, onUpdate }) => {
  const {
    from,
    to,
    slideDefaultValue,
    value,
    steps,
    refValueFrom,
    roundFactor,
  } = config;
  const { answers } = useSelector((state) => state.form);
  const [currentValue, setCurrentValue] = useState(value || null);
  const [sliderValue, setSliderValue] = useState(
    steps?.length ? steps.indexOf(value) : slideDefaultValue
  );

  const handleOnChange = (e) => {
    const value = e.target.value;
    setSliderValue(value);
    steps?.length ? handleStepsChange(value) : onUpdate(value);
  };

  const handleStepsChange = (stepValue) => {
    const currentValue = steps[stepValue - 1];
    setCurrentValue(currentValue);
    onUpdate(currentValue);
  };

  const printRefRange = () => {
    const maxValue = answers[refValueFrom];
    const currentValue = (Number(maxValue) * Number(sliderValue)) / 100;
    const rangeHeight = Math.ceil(currentValue / roundFactor) * roundFactor;
    const rangeLow = rangeHeight - roundFactor + 1;
    return `${currencyFormat(rangeLow)} to ${currencyFormat(rangeHeight)}`;
  };

  const printStepRange = () => {
    const valPos = steps.indexOf(currentValue);
    const formattedCurrentValue = currencyFormat(currentValue);

    if (valPos <= 0) {
      return `${formattedCurrentValue} or Less`;
    }

    if (valPos === steps.length - 1) {
      return `${formattedCurrentValue} or More`;
    }

    const prevValue = steps[valPos - 1];
    const formattedPrevValue = currencyFormat(prevValue + 1);

    return `${formattedPrevValue} to ${formattedCurrentValue}`;
  };

  const printableRange = steps?.length ? printStepRange() : printRefRange();

  useEffect(() => {
    if (steps?.length && currentValue) {
      onUpdate(currentValue);
      return;
    }
    onUpdate(slideDefaultValue);
  }, []);

  return (
    <FormSlider
      label={printableRange}
      min={1}
      step={1}
      max={steps?.length || to.value}
      value={sliderValue}
      onChange={handleOnChange}
      fromLabel={from.label}
      toLabel={to.label}
    />
  );
};

QuoteMortgageSlider.propTypes = {
  config: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default QuoteMortgageSlider;
