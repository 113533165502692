import { valuesFormatter } from '@/utils/helpers';

const mapAnswers = (questions, answers) => {
  const payload = {};
  for (const answerId in answers) {
    const q = questions[`q${answerId}`];

    if (q?.forSubmit?.omit) {
      // If this question isn't to be included in the payload. Likely, it was a selector for the following question.
      continue;
    }

    // For questions with 'skip if' conditions, check to see if we should omit this answer from the final results
    if (q.skipIf) {
      let skip = false;

      // If any of the questions which match 'skip if' match
      for (const key in q.skipIf) {
        if (answers[key] === q.skipIf[key]) {
          skip = true;
          break;
        }
      }

      // Since we are supposed to skip this one, go to the next question
      if (skip) {
        continue;
      }
    }

    // If this property has a group of values. Only works to output objects
    if (q?.forSubmit?.group_as) {
      payload[q.forSubmit.group_as] = {
        ...payload[q.forSubmit.group_as],
        [q.type]: valuesFormatter(answers[answerId], q.type),
      };
      continue;
    }

    // If the values are in an array
    if (Array.isArray(answers[answerId]) && questions[`q${answerId}`].options) {
      answers[answerId].forEach((answer, index) => {
        // If it's an array, but each result is supposed to be 'flattened' in the final submission (e.g., child_1_name = 'Bob')
        if (answer && q.forSubmit?.flatten) {
          const answerName2 = `${q.name}_${answer}`;
          payload[answerName2] = true;
          return;
        }

        // If it's an array, there are options for this question, and the options use a 'type', use the option type as the key
        if (answer && q?.options[index]?.type) {
          // Use the existing values, or make a new array
          payload[q.name] = payload[q.name] || [];
          // Counter used to loop through options[] to get type value
          let j = 0;
          const newObj = {};

          // For each key in the object
          for (const key in answer) {
            newObj[q.options[j].type] = valuesFormatter(
              answer[key],
              q.options[j].type
            );
            j++;
          }

          // Add this new object to the existing array
          payload[q.name].push(newObj);

          return;
        }

        // If the answer is an object (not an array)
        if (typeof answer === 'object') {
          for (const key in answer) {
            const answerName1 = `${q.name}_${key}`;
            payload[answerName1] = answer[key];
          }
          // early return, goes to next iteration
          return;
        }

        // Otherwise, it's just an array of values. Put the array as the answer.
        payload[q.name] = answers[answerId];
      });

      continue;

      // If the values are in an object
    } else if (typeof answers[answerId] === 'object' && q.options) {
      let i = 1;
      for (const option of q.options) {
        payload[option.name] = answers[answerId][i];
        i++;
      }
      continue;
    }

    // If there's a type, we can try and format it
    if (q.type) {
      payload[q.name] = valuesFormatter(answers[answerId], q.type);
      continue;
    }

    // Otherwise, just use the name as the key
    payload[q.name] = answers[answerId];
  }
  return payload;
};

export default mapAnswers;
