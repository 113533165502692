import Joi from 'joi';

// Benefit Type
export const benefitTypeSchema = Joi.array().items(
  Joi.string().valid(
    'emergency_care_hospitalization',
    'preventative_care',
    'dental',
    'vision',
    'telehealth',
    'coverage_for_preexisting_conditions'
  )
);

export default benefitTypeSchema;
