const shouldQuestionBeSkipped = (allAnswers, skipItem) => {
  // Answers == all answers
  // SkipItem == Skip obj to use
  for (const key in skipItem) {
    // Guard clause: If this key isn't in the answers, continue loop
    if (!(key in allAnswers)) {
      continue;
    }

    // If this item is an array, and the array includes the answer of the test question
    if (
      Array.isArray(skipItem[key]) &&
      skipItem[key].includes(allAnswers[key])
    ) {
      // since the array contains the answer in the test question, skip this one
      return true;
    }

    // Since it's not an array, if the value matches, return true
    if (skipItem[key] === allAnswers[key]) {
      return true;
    }
  }

  return false;
};

export const getNextQuestion = (currentQuestion, questions, answers, total) => {
  let next = 1;

  for (let i = currentQuestion + 1; i <= total; i++) {
    const nextQ = questions[`q${i}`];
    if (
      nextQ &&
      nextQ.skipIf &&
      shouldQuestionBeSkipped(answers, nextQ.skipIf)
    ) {
      next++;
    } else {
      break;
    }
  }
  return next;
};

export const getPreviousQuestion = (currentQuestion, questions, answers) => {
  let previous = 1;
  for (let i = currentQuestion - 1; i >= 1; i--) {
    const prevQ = questions[`q${i}`];

    if (
      prevQ &&
      prevQ.skipIf &&
      shouldQuestionBeSkipped(answers, prevQ.skipIf)
    ) {
      previous++;
      /*
      const skipKey = Object.keys(prevQ.skipIf)[0];
      if (skipKey in answers && answers[skipKey] === prevQ.skipIf[skipKey]) {
        previous++;
      }
      */
    } else if ([''].includes(answers[i])) {
      previous++;
    } else {
      break;
    }
  }
  return previous;
};

export const valuesFormatter = (value, type = null) => {
  // Guard
  if (!type) return value;

  if (type === 'dob') {
    // Replace / with -
    return value.replaceAll('/', '-');
  }

  if (type === 'phone') {
    // Remove any non-numbers
    return value.replace(/\D/g, '');
  }

  return value;
};

/**
 * randId
 * Generate a random ID based on Math.random().
 * @param min Number
 * @param max Number
 * @returns string
 */
export const randId = (min = 2, max = 6) =>
  Math.random().toString(36).substr(min, max);

/**
 * currencyFormat
 * Format a number in a given currency.
 * @param amount Number
 * @param format String
 * @param currency String
 * @returns string
 */
export const currencyFormat = (amount, format = 'en-US', currency = 'USD') => {
  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  }).format(amount);
};
