import { years, otherYears } from '../years';
import { makes, otherMakes } from '../make';

export default {
  currentQuestion: 1,
  total: 18,
  questions: {
    q1: {
      name: 'zip',
      label: 'Enter Zip Code to begin',
      formType: 'zip-code',
      type: 'zip-code',
      autoNext: false,
      btnLabel: 'Check Rates',
      validate: {
        required: true,
        length: 5,
      },
    },
    q2: {
      name: 'vehicle_1_year',
      label: 'Select Your Vehicle Year',
      type: 'vehicle_year',
      formType: 'select',
      autoNext: true,
      options: years,
      otherOptions: otherYears,
      otherOptionsLabel: 'Previous Years',
    },
    q3: {
      name: 'vehicle_1_make',
      label: 'Select Your Vehicle Make',
      type: 'vehicle_make',
      formType: 'select',
      autoNext: true,
      options: makes,
      otherOptions: otherMakes,
      otherOptionsLabel: 'Other Makes',
    },
    q4: {
      name: 'vehicle_1_model',
      label: 'Select Your Vehicle Model',
      type: 'vehicle_model',
      formType: 'vehicle-model',
      autoNext: true,
      options: [],
    },
    q5: {
      label: 'Add Second Vehicle? (Save Additional 20%)',
      type: 'vehicle_add',
      formType: 'select',
      autoNext: true,
      options: ['Yes', 'No'],
      forSubmit: {
        omit: true,
      },
    },
    q6: {
      name: 'vehicle_2_year',
      label: 'Select Second Vehicle Year',
      type: 'vehicle_year',
      formType: 'select',
      autoNext: true,
      options: years,
      skipIf: { 5: 'No' },
    },
    q7: {
      name: 'vehicle_2_make',
      label: 'Select Second Vehicle Make',
      type: 'vehicle_make',
      formType: 'select',
      autoNext: true,
      options: makes,
      otherOptions: otherMakes,
      otherOptionsLabel: 'Other Makes',
      skipIf: { 5: 'No' },
    },

    q8: {
      name: 'vehicle_2_model',
      label: 'Select Second Vehicle Model',
      type: 'vehicle_model',
      formType: 'vehicle-model',
      autoNext: true,
      options: [],
      skipIf: { 5: 'No' },
    },
    q9: {
      name: 'insured_recently',
      label: 'Have you had auto insurance in the past 30 days?',
      type: 'insurance_yn',
      formType: 'select',
      autoNext: true,
      options: ['Yes', 'No'],
    },
    q10: {
      name: 'gender',
      label: 'Gender',
      type: 'gender',
      formType: 'select',
      autoNext: true,
      options: ['Male', 'Female', 'Non-Binary'],
    },
    q11: {
      name: 'is_married',
      label: 'Are you currently married?',
      type: 'married_yn',
      formType: 'select',
      autoNext: true,
      options: ['Yes', 'No'],
    },
    q12: {
      name: 'is_homeowner',
      label: 'Homeowner?',
      type: 'homeowner_yn',
      formType: 'select',
      autoNext: true,
      options: ['Yes', 'No'],
    },
    q13: {
      name: 'receive_quotes',
      label:
        'Would you like to also receive renters insurance policy quotes? You may be able to bundle and save even more on your auto policy.',
      type: 'renters_quotes_yn',
      formType: 'select',
      autoNext: true,
      options: ['Yes', 'No'],
    },

    q14: {
      label: 'Has anyone on this policy had:',
      type: 'accident_yn',
      formType: 'level-select',
      autoNext: false,
      btnLabel: 'Continue',
      forSubmit: { flatten: true },
      options: [
        {
          name: 'record_has_fault_accident_3y',
          id: 1,
          label:
            'An at-fault accident in the past <strong>three (3) years</strong>?',
          options: ['Yes', 'No'],
          formType: 'select',
          validate: {
            required: true,
          },
        },
        {
          name: 'record_has_multiple_tickets_3y',
          id: 2,
          label:
            '<strong>Two (2) or more</strong> tickets in the past <strong>three (3) years</strong>?',
          options: ['Yes', 'No'],
          formType: 'select',
          validate: {
            required: true,
          },
        },
        {
          name: 'record_has_dui_3y',
          id: 3,
          label:
            'A DUI conviction in the past <strong>three (3) years</strong>?',
          options: ['Yes', 'No'],
          formType: 'select',
          validate: {
            required: true,
          },
        },
      ],
    },
    q15: {
      name: 'military_member',
      label:
        'Are either you or your spouse an active member, or an honorably discharged veteran of the US military?',
      type: 'military_yn',
      formType: 'select',
      autoNext: true,
      options: ['Yes', 'No'],
    },
    q16: {
      name: 'age_range',
      label: 'What is your Age?',
      type: 'age_rate',
      formType: 'select',
      autoNext: true,
      options: [
        {
          label: '< 18',
          value: 1,
        },
        {
          label: '18-24',
          value: 2,
        },
        {
          label: '25-34',
          value: 3,
        },
        {
          label: '35-49',
          value: 4,
        },
        {
          label: '50-64',
          value: 5,
        },
        {
          label: '65+',
          value: 6,
        },
      ],
    },
    q17: {
      type: 'info_yn',
      formType: 'level-select',
      autoNext: false,
      btnLabel: 'Continue',
      forSubmit: { flatten: true },
      validate: {
        required: true,
      },
      options: [
        {
          name: 'name_first',
          id: 1,
          formType: 'text',
          itemLabel: 'First Name',
          validate: {
            required: true,
          },
        },
        {
          name: 'name_last',
          id: 2,
          formType: 'text',
          itemLabel: 'Last Name',
          validate: {
            required: true,
          },
        },
        {
          name: 'email',
          id: 3,
          formType: 'email',
          itemLabel: 'Email Address',
          validate: {
            required: true,
            regex: 'email',
          },
        },
      ],
    },
    q18: {
      label: 'Last Step!',
      type: 'address_el',
      formType: 'level-select',
      autoNext: false,
      btnLabel: 'Continue',
      forSubmit: { flatten: true },
      validate: {
        required: true,
      },
      options: [
        {
          name: 'address_1',
          id: 1,
          formType: 'text',
          itemLabel: 'Address',
          validate: {
            required: true,
          },
        },
        {
          name: 'phone',
          id: 2,
          formType: 'phone',
          itemLabel: 'Phone Number',
          validate: {
            required: true,
            regex: 'phone',
          },
        },
      ],
    },
  },
  info: {
    firstname: '',
  },
  answers: {
    14: {
      1: 'No',
      2: 'No',
      3: 'No',
    },
  },
  errors: {},
};
