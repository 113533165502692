export default {
  currentQuestion: 1,
  total: 10,
  questions: {
    q1: {
      name: 'loan_type',
      label: 'Start your mortgage search here!',
      formType: 'select',
      type: 'select',
      options: [
        { label: 'Home Refinance', value: 'refinance' },
        { label: 'New Home Purchase', value: 'mortgage' },
      ],
      autoNext: true,
    },
    q2: {
      name: 'credit_score_qualitative',
      label: 'Your Credit Profile',
      formType: 'select',
      type: 'select',
      autoNext: true,
      options: [
        { label: 'Excellent', value: 'excellent' },
        { label: 'Very Good', value: 'very_good' },
        { label: 'Good', value: 'good' },
        { label: 'Fair', value: 'average' },
        { label: 'Poor', value: 'poor' },
      ],
    },
    q3: {
      name: 'has_bankruptcy_last_three_years',
      label: 'Bankruptcy or Foreclosure in the Last 3 Years?',
      formType: 'select',
      type: 'select',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    q4: {
      name: 'estimated_mortgage_balance',
      label: 'Loan Balance',
      formType: 'mortgage-slider',
      type: 'mortgage-slider',
      config: {
        value: 150000,
        from: {
          label: '$50k',
          value: 50000,
        },
        to: {
          label: '$1M+',
          value: 1000000,
        },
        steps: [
          50000,
          100000,
          150000,
          200000,
          250000,
          300000,
          350000,
          400000,
          450000,
          500000,
          550000,
          600000,
          650000,
          700000,
          750000,
          800000,
          850000,
          900000,
          950000,
          1000000,
          1000001,
        ],
      },
      autoNext: false,
      btnLabel: 'Continue',
    },

    q5: {
      name: 'has_military_service',
      label: 'Active or Previous Military Service?',
      formType: 'select',
      type: 'militaryservice',
      autoNext: true,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },

    q6: {
      name: 'property_type',
      label: 'Type of Property',
      formType: 'select',
      type: 'propertytype',
      autoNext: true,
      options: [
        { label: 'Single Family', value: 'single_family' },
        { label: 'Multi Unit', value: 'multi_family' },
        { label: 'Townhouse', value: 'townhouse' },
        { label: 'Condo', value: 'condo' },
        { label: 'Mobile', value: 'mobile' },
      ],
    },
    q7: {
      name: 'property_use',
      label: 'Property Use',
      formType: 'select',
      type: 'propertyuse',
      autoNext: true,
      options: [
        { label: 'Primary Residence', value: 'primary_residence' },
        { label: 'Vacation Home', value: 'second_home' },
        { label: 'Investment', value: 'investment_property' },
      ],
    },

    q8: {
      name: 'estimated_property_value',
      label: 'Property Value',
      formType: 'mortgage-slider',
      type: 'mortgage-slider',
      config: {
        value: 150000,
        from: {
          label: '$50k',
          value: 50000,
        },
        to: {
          label: '$1M+',
          value: 1000000,
        },
        steps: [
          50000,
          100000,
          150000,
          200000,
          250000,
          300000,
          350000,
          400000,
          450000,
          500000,
          550000,
          600000,
          650000,
          700000,
          750000,
          800000,
          850000,
          900000,
          950000,
          1000000,
          1000001,
        ],
      },
      autoNext: false,
      btnLabel: 'Continue',
    },
    q9: {
      name: 'property_state',
      label: 'Property State',
      formType: 'list-select',
      type: 'state',
      autoNext: false,
      btnLabel: 'Continue',
      options: [
        { label: 'Alabama', value: 'AL' },
        { label: 'Alaska', value: 'AK' },
        { label: 'American Samoa', value: 'AS' },
        { label: 'Arizona', value: 'AZ' },
        { label: 'Arkansas', value: 'AR' },
        { label: 'California', value: 'CA' },
        { label: 'Colorado', value: 'CO' },
        { label: 'Connecticut', value: 'CT' },
        { label: 'Delaware', value: 'DE' },
        { label: 'District of Columbia', value: 'DC' },
        { label: 'Federated States of Micronesia', value: 'FM' },
        { label: 'Florida', value: 'FL' },
        { label: 'Georgia', value: 'GA' },
        { label: 'Guam', value: 'GU' },
        { label: 'Hawaii', value: 'HI' },
        { label: 'Idaho', value: 'ID' },
        { label: 'Illinois', value: 'IL' },
        { label: 'Indiana', value: 'IN' },
        { label: 'Iowa', value: 'IA' },
        { label: 'Kansas', value: 'KS' },
        { label: 'Kentucky', value: 'KY' },
        { label: 'Louisiana', value: 'LA' },
        { label: 'Maine', value: 'ME' },
        { label: 'Marshall Islands', value: 'MH' },
        { label: 'Maryland', value: 'MD' },
        { label: 'Massachusetts', value: 'MA' },
        { label: 'Michigan', value: 'MI' },
        { label: 'Minnesota', value: 'MN' },
        { label: 'Mississippi', value: 'MS' },
        { label: 'Missouri', value: 'MO' },
        { label: 'Montana', value: 'MT' },
        { label: 'Nebraska', value: 'NE' },
        { label: 'Nevada', value: 'NV' },
        { label: 'New Hampshire', value: 'NH' },
        { label: 'New Jersey', value: 'NJ' },
        { label: 'New Mexico', value: 'NM' },
        { label: 'New York', value: 'NY' },
        { label: 'North Carolina', value: 'NC' },
        { label: 'North Dakota', value: 'ND' },
        { label: 'Northern Mariana Islands', value: 'MP' },
        { label: 'Ohio', value: 'OH' },
        { label: 'Oklahoma', value: 'OK' },
        { label: 'Oregon', value: 'OR' },
        { label: 'Palau', value: 'PW' },
        { label: 'Pennsylvania', value: 'PA' },
        { label: 'Puerto Rico', value: 'PR' },
        { label: 'Rhode Island', value: 'RI' },
        { label: 'South Carolina', value: 'SC' },
        { label: 'South Dakota', value: 'SD' },
        { label: 'Tennessee', value: 'TN' },
        { label: 'Texas', value: 'TX' },
        { label: 'Utah', value: 'UT' },
        { label: 'Vermont', value: 'VT' },
        { label: 'Virgin Islands', value: 'VI' },
        { label: 'Virginia', value: 'VA' },
        { label: 'Washington', value: 'WA' },
        { label: 'West Virginia', value: 'WV' },
        { label: 'Wisconsin', value: 'WI' },
        { label: 'Wyoming', value: 'WY' },
      ],
      validate: {
        required: true,
      },
    },
    q10: {
      name: 'address_zip',
      label: 'Property Zip Code',
      formType: 'zip-code',
      type: 'zipcode',
      autoNext: false,
      btnLabel: 'Continue',
      validate: {
        required: true,
        length: 5,
      },
    },
  },
  info: {
    firstname: '',
  },

  answers: {},

  errors: {},
};
