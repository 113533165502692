import React from 'react';
import PropTypes from 'prop-types';

const QuoteSkip = ({ onClick }) => {
  const clickHandler = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a
      href="#"
      onClick={clickHandler}
      className="text-center md:text-left md:ml-4 text-blue my-6 md:my-3 mr-2"
    >
      Skip
    </a>
  );
};

QuoteSkip.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default QuoteSkip;
