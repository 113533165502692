import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import QuoteForm from '@/components/QuoteForm';
import YourQuotes from '@/components/YourQuotes';
import CompareRatesFrom from '@/components/CompareRatesFrom';
import mapAnswers from './mapAnswers';
import axios from '@/config/axios';
import amplitude from 'amplitude-js';
import { css } from '@emotion/css';
import { Helmet } from 'react-helmet-async';

const AppSurvey = ({ loading = true, onError, urlParams }) => {
  const history = useHistory();
  const { questions, answers, isComplete } = useSelector((store) => store.form);
  const [showQuotes, setShowQuotes] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const [loadingRates, setLoadingRates] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [buyerId, setBuyerId] = useState('');
  const [surveyType, setSurveyType] = useState('');
  const [goInDirection, setGoInDirection] = useState('');

  const quoteFormWrapperStyles = css`
    display: ${showQuotes ? 'none' : 'block'};
  `;

  const submitForm = async () => {
    setShowQuotes(true);

    const params = {
      ...urlParams,
      payload: mapAnswers(questions, answers),
    };

    try {
      const { data } = await axios().post('survey/submit', params);

      if (!!data.result?.redirect_url) {
        window.location.href = data.result.redirect_url;
        return;
      }

      setQuotes(data.result);
      setLoadingRates(false);
    } catch (err) {
      amplitude.getInstance().logEvent('error_frontend', {
        endpoint: 'survey/submit',
        error: err?.response?.data,
      });
      onError();
    }
  };

  const sendClick = async () => {
    try {
      const { data } = await axios().post('survey/start', urlParams);

      const buyerId = data?.result?.buyer_id;
      const surveyType = data?.result?.type;

      if (buyerId) {
        setBuyerId(buyerId);
      }

      if (surveyType) {
        setSurveyType(surveyType);
      }
    } catch (err) {
      amplitude.getInstance().logEvent('error_frontend', {
        endpoint: 'survey/start',
        error: err?.response?.data,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(urlParams).length) {
      sendClick();
    }
  }, [urlParams]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (location.key && locationKeys[1] === location.key) {
          // Use destructuring to remove the first item from the previous keys
          setLocationKeys(([_, ...prevKeys]) => prevKeys);
          setGoInDirection('forward');
        } else {
          // Add the location key as the first item in the array
          setLocationKeys((prevKeys) => [location.key, ...prevKeys]);
          setGoInDirection('backward');
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if (isComplete) {
      submitForm();
    }
  }, [isComplete]);

  const isLifeNoSurvey = surveyType === 'life-no-survey';

  useEffect(() => {
    if (isLifeNoSurvey) {
      //const externalScript = document.createElement('script');
      //externalScript.src = '/pixels.js';
      //externalScript.async = true;
      //document.head.append(externalScript);

      const noScript = document.createElement('noscript');
      const noScriptImg = document.createElement('img');
      noScriptImg.src =
        'https://www.facebook.com/tr?id=202572165746235&ev=PageView&noscript=1';
      noScriptImg.height = '1';
      noScriptImg.width = '1';
      noScriptImg.style = 'display:none';
      noScript.append(noScriptImg);
      document.body.append(noScript);

      return () => {
        //externalScript.remove();
        noScript.remove();
      };
    }
  }, [isLifeNoSurvey]);

  if (showQuotes || isComplete) {
    return <YourQuotes quotes={quotes} loading={loadingRates} />;
  }

  return (
    <>
      <Helmet>
        {isLifeNoSurvey && (
          <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '202572165746235');fbq('track', 'PageView');`}
          </script>
        )}
      </Helmet>

      <div className="flex-grow">
        {(showQuotes || isComplete) && (
          <YourQuotes quotes={quotes} loading={loadingRates} />
        )}

        {!loading && (
          <div className={quoteFormWrapperStyles}>
            <QuoteForm
              onShowQuotes={submitForm}
              type={surveyType}
              changeQuestionDirection={goInDirection}
              onChangeDirectionCallback={() => setGoInDirection('')}
              quotesIsVisible={showQuotes} // TODO: ask Riley about this
              changeQuotesVisibilityCallback={(val) => setShowQuotes(val)}
            />
          </div>
        )}
      </div>
    </>
  );
};

AppSurvey.propTypes = {
  loading: PropTypes.bool,
  urlParams: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AppSurvey;
