import axios from 'axios';

const axiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Add baseUrl from .env file
  });

  // Request interceptors can be used to append elements to the request,
  // like a JWT token
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('jwt_token');
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptors can be use to handle global errors, redirects etc.
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('jwt_token');
        // Push to login page
        return Promise.reject();
      }
      if (error?.response?.status === 404) {
        // handle 404 errors
      }
      // Handle other status here or reject to allow local handling with
      // try / catch blocks for instance
      return Promise.reject(error);
    }
  );
  return instance;
};

export default axiosInstance;
