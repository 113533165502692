import autoZip from './auto-zip';
import autoTpl from './auto-tpl';
import autoFull from './auto-full';
import medicareFull from './medicare-full';
import mortgageRefiFull from './mortgage-refi-full';
import mortgageRefiTpl from './mortgage-refi-tpl';
import noSurvey from './no-survey';
import lifeNoSurvey from './life-no-survey';

export default {
  'auto-zip': autoZip,
  'no-survey': noSurvey,
  'auto-tpl': autoTpl,
  'auto-full': autoFull,
  'medicare-full': medicareFull,
  'mortgage-refi-full': mortgageRefiFull,
  'mortgage-refi-tpl': mortgageRefiTpl,
  'life-no-survey': lifeNoSurvey,
};
