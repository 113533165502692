import Joi from 'joi';
import nameSchema from './nameSchema';
import genderSchema from './nameSchema';
import benefitTypeSchema from './benefitTypeSchema';
import dependantSchema from './dependantSchema';
import coverageTimeSchema from './coverageTimeSchema';
import phoneSchema from './phoneSchema';
import emailSchema from './emailSchema';
import dobSchema from './dobSchema';
import booleanSchema from './booleanSchema';
import zipSchema from './zipSchema';
import childrenSchema from './childrenSchema';

// Beneficiary (the person filling out the survey)
const beneficiarySchema = Joi.object({
  gender: genderSchema,
  dob: dobSchema,
  is_smoker: booleanSchema,
  is_in_medicare: booleanSchema,
  window: coverageTimeSchema,
  interests: benefitTypeSchema,
  zip: zipSchema,
  name_first: nameSchema.required(),
  name_last: nameSchema.required(),
  email: emailSchema.required(),
  mobile_phone: phoneSchema.required(),
  spouse: dependantSchema,
  include_children_in_plan: booleanSchema,
  children: childrenSchema,
});

export default beneficiarySchema;
