import Joi, { valid } from 'joi';
import schemas from '@/schemas';

const regx = {
  birthdate: /^(19|20)\d\d+[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
  email: /^.+@[a-zA-Z_]+?\.[a-zA-Z]{2,10}$/,
  phone: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
};

const validateQuestion = (
  surveyType,
  question,
  answers,
  validate,
  formType,
  options,
  name = ''
) => {
  let errors = [];
  const surveySchemas = schemas?.[surveyType] || {};

  if (!Object.keys(surveySchemas).length) return [true, []];

  if (validate) {
    let questionSchema = schemas?.[surveyType]?.[name] || Joi.any();
    questionSchema = questionSchema.required();

    // If this question requires an exact length
    /*if (validate.length) {
      schema.length(validate.length);
    }*/

    // General validation
    // const errorName = name || 'This field';
    const errorMsg = validate.message
      ? validate.message
      : name
      ? `${name} is required`
      : 'This field is required';

    // formType: multi-select
    if (
      validate.required &&
      formType === 'multi-select' &&
      !answers[question].length
    ) {
      errors = [
        {
          qId: question,
          error: `At least one selection is required`,
        },
      ];

      return [false, errors];
    }

    // formType: level-select
    if (formType === 'level-select') {
      // Temp val for this input
      let isValid = true;

      for (const option of options) {
        let val = '';

        const levelQuestionSchema = surveySchemas[option.name];

        console.log('checking...');
        // Just in case there isn't a schema for this for some reason, skip ahead
        if (!levelQuestionSchema) {
          console.log(option.name);
          console.log('Not included!');
          continue;
        }

        // If there is an option ID for this answer, set val as the answer
        if (answers[question]?.[option.id]) {
          val = answers[question][option.id];
        }

        // Validate the string against the schema
        const { error } = levelQuestionSchema.validate(val);

        // NEXT: get the message from error
        if (option.validate.required && error) {
          const errMsg1 = option?.validate.message
            ? option.validate.message
            : 'This field is required';
          errors.push({
            qId: question,
            subId: option.id,
            error: errMsg1,
          });

          if (isValid) {
            isValid = false;
          }
        }
      }

      return [isValid, errors];
    }

    // Required
    if (validate.required) {
      // Validate against the schema
      const { error } = questionSchema.validate(answers[question]);

      if (error) {
        errors = [
          {
            qId: question,
            error: errorMsg,
          },
        ];

        return [false, errors];
      }
    }
  }

  // Specialized "Add children" component, outside of normal 'validate' condition
  if (formType === 'add-children') {
    let isValid = true;
    const childrenMsgLookup = {
      dob: 'Please enter a valid date of birth',
      gender: 'Please select gender',
    };

    const childrenQuestionTypeLookup = ['dob', 'gender', 'is_smoker'];

    // Add-Children values are an array
    for (let i = 0; i < answers[question].length; i++) {
      let j = 0;

      for (const answerKey in answers[question][i]) {
        if (options[j]?.validate?.required) {
          const childType = childrenQuestionTypeLookup[j];

          const { error } = surveySchemas[childType].validate(
            answers[question][i][answerKey]
          );

          if (error) {
            errors.push({
              qId: question,
              subId: answerKey,
              error: childrenMsgLookup[childrenQuestionTypeLookup[i]],
            });
            isValid = false;
          }
        }

        j++;
      }
    }

    // If something was invalid, return false
    return [isValid, errors];
  }

  // If none of that
  return [true, errors];
};

export default validateQuestion;
