import Joi from 'joi';
import beneficiarySchema from './beneficiarySchema';
import nameSchema from './nameSchema';
import genderSchema from './nameSchema';
import benefitTypeSchema from './benefitTypeSchema';
import dependantSchema from './dependantSchema';
import coverageTimeSchema from './coverageTimeSchema';
import phoneSchema from './phoneSchema';
import emailSchema from './emailSchema';
import dobSchema from './dobSchema';
import booleanSchema from './booleanSchema';
import zipSchema from './zipSchema';
import childrenSchema from './childrenSchema';

export const schemas = {
  'auto-zip': {
    zip: zipSchema,
  },
  'medicare-full': {
    name_first: nameSchema,
    name_last: nameSchema,
    gender: genderSchema,
    interests: benefitTypeSchema,
    window: coverageTimeSchema,
    phone: phoneSchema,
    beneficiary: beneficiarySchema,
    dob: dobSchema,
    spouse_date_of_birth: dobSchema,
    is_smoker: booleanSchema,
    is_in_medicare: booleanSchema,
    window: coverageTimeSchema,
    interests: benefitTypeSchema,
    zip: zipSchema,
    email: emailSchema,
    mobile_phone: phoneSchema,
    spouse: dependantSchema,
    include_children_in_plan: booleanSchema,
    children: childrenSchema,
  },
  'auto-full': {
    zip: zipSchema,
    birthdate: dobSchema,
    email: emailSchema,
    mobile_phone: phoneSchema,
    phone: phoneSchema,
    gender: genderSchema,
    name_first: nameSchema,
    name_last: nameSchema,
    address_1: nameSchema,
    phone: phoneSchema,
  },
  'mortgage-refi-full': {
    zip: zipSchema,
    address_1: nameSchema,
    name_first: nameSchema,
    name_last: nameSchema,
    phone: phoneSchema,
    email: emailSchema,
    state: nameSchema,
    city: nameSchema,
  },
  'mortgage-refi-tpl': {
    zip: zipSchema,
    state: nameSchema,
  },
};

export default schemas;
