import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import FormSlider from '@/components/FormSlider';
import { currencyFormat } from '@/utils/helpers';
import { goToNextQuestion } from '@/store/slices/formSlice';

const QuoteAdditionalCash = ({ onUpdate }) => {
  const dispatch = useDispatch();
  const { currentQuestion, answers } = useSelector((state) => state.form);

  const [currentValue, setCurrentValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const getToLabel = currencyFormat(maxValue);

  const makeLabel = () => {
    if (currentValue === 0) {
      return `$0 (no cash)`;
    }
    return currencyFormat(currentValue);
  };

  const handleOnChange = (e) => {
    const value = e.target.value;
    setCurrentValue(value);
    onUpdate(value);
  };

  const getMaxValue = () => {
    const roundFactor = 10000;
    const epv = answers[currentQuestion - 2];
    const embPercent = answers[currentQuestion - 1];
    const embAmount = (epv * embPercent) / 100;
    const embRangeAmount = Math.ceil(embAmount / roundFactor) * roundFactor;
    const calcMaxValue = epv - embRangeAmount;
    setMaxValue(calcMaxValue);
  };

  useEffect(() => {
    getMaxValue();
  }, []);

  if (!maxValue) {
    return <></>;
  }

  return (
    <div>
      <FormSlider
        label={makeLabel()}
        min={0}
        step={5000}
        max={maxValue}
        value={currentValue}
        onChange={handleOnChange}
        fromLabel="$0"
        toLabel={getToLabel}
      />
    </div>
  );
};

QuoteAdditionalCash.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default QuoteAdditionalCash;
