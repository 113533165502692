import Joi from 'joi';
import benefitTypeSchema from './benefitTypeSchema';

// Dependant (e.g., child or spouse)
export const dependantSchema = Joi.object({
  gender: benefitTypeSchema.required(),
  dob: Joi.date().iso().required(),
  is_smoker: Joi.boolean().required(),
});

export default dependantSchema;
