import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const QuoteFormSelect = ({ options, onClick }) => {
  const { currentQuestion, answers, errors } = useSelector(
    (store) => store.form
  );

  const setDefaultValue = () => {
    if (options?.[0]?.value) {
      const findDefaultOption = options.find(
        (option) => option.value === answers[currentQuestion]
      );
      return findDefaultOption ? findDefaultOption.value : 'Select Option';
    }
    return options.includes(answers[currentQuestion])
      ? answers[currentQuestion]
      : 'Select Option';
  };

  const baseLabel = 'Select Option';

  const [value, setValue] = useState(setDefaultValue());

  const hasValue = value && value !== 'Select Option';

  const handleOnClick = (option) => {
    if (!option) return;
    setValue(option);
    onClick(option);
  };

  useEffect(() => {
    setValue(setDefaultValue());
  }, [options]);

  const selectStyle = css`
    select {
      appearance: none;
    }
  `;

  const error = errors[currentQuestion];

  return (
    <div className="relative sm:max-w-xxs">
      <div className={`relative ${selectStyle}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="7"
          viewBox="0 0 11 7"
          className="transform transition-all rotate-180 duration-100 absolute right-4 top-5"
        >
          <path
            fill={hasValue ? '#fff' : '#9191A0'}
            fillRule="evenodd"
            d="M10.1923882,4.53553391 C10.5829124,4.9260582 10.5829124,5.55922318 10.1923882,5.94974747 C9.80186386,6.34027176 9.16869888,6.34027176 8.77817459,5.94974747 L5.24264069,2.41421356 L1.70710678,5.94974747 C1.31658249,6.34027176 0.683417511,6.34027176 0.292893219,5.94974747 C-0.0976310729,5.55922318 -0.0976310729,4.9260582 0.292893219,4.53553391 L4.53553391,0.292893219 C4.89816361,-0.0697364807 5.470009,-0.0956386021 5.86252666,0.215186855 L5.94974747,0.292893219 L10.1923882,4.53553391 Z"
          />
        </svg>

        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          onChange={(e) => handleOnClick(e.target.value)}
          value={value}
          className={`${
            hasValue
              ? 'bg-blue text-white border-blue '
              : 'bg-gray-200 text-blue border-gray'
          } text-left rounded px-4  border    w-full sm:max-w-xxs min-h-12.5 flex items-center justify-between`}
        >
          <option value="" style={{ display: 'none' }}>
            {baseLabel}
          </option>
          {options.map((option, i) => (
            <option
              defaultValue={
                option?.value ? option.value === value : option === value
              }
              value={option?.value ? option.value : option}
              key={`o-${i}`}
            >
              {option?.label ? option.label : option}
            </option>
          ))}
        </select>
        {error && <div className="mt-1 text-red text-sm">{error}</div>}
      </div>
    </div>
  );
};

QuoteFormSelect.propTypes = {
  options: PropTypes.array.isRequired,
  otherOptionsLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default QuoteFormSelect;
