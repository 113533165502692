import PropTypes from 'prop-types';

const FormSlider = ({
  label,
  min,
  step,
  max,
  value = '',
  onChange,
  fromLabel,
  toLabel,
}) => {
  return (
    <div className="relative max-w-md">
      <div className="text-altGreen font-bold text-center text-lg mb-3">
        {label}
      </div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />
      <div className="flex justify-between font-medium text-gray-800">
        <div>{fromLabel}</div>
        <div>{toLabel}</div>
      </div>
    </div>
  );
};

FormSlider.propTypes = {
  label: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  fromLabel: PropTypes.string.isRequired,
  toLabel: PropTypes.string.isRequired,
};

export default FormSlider;
