import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

import amplitude from 'amplitude-js';
import AppSurvey from '@/components/AppSurvey';
import SurveyError from '@/components/SurveyError';

const HomePage = () => {
  const location = useLocation();

  const [urlParams, setUrlParams] = useState({});
  const [loading, setLoading] = useState(true);
  const [unableShowQuotes, setUnableShowQuotes] = useState(false);

  const getOffer = () => {
    const query = new URLSearchParams(location.search);

    setDevDefaultParams(query);
    setUrlParams({
      source: query.get('source'),
      clickid: query.get('clickid'),
      pubid: query.get('pubid'),
      c: query.get('c'),
      s: query.get('s'),
      preview: query.get('preview'),
      // tm: query.get('tm'), // TEMP: removing tm param
    });
    setLoading(false);
  };

  const setDevDefaultParams = (query) => {
    if (
      // !query.get('tm') && // TEMP: removing tm param
      !query.get('c') &&
      !query.get('s') &&
      process.env.NODE_ENV === 'development'
    ) {
      // So you don't have to pass them manually on dev ;)
      window.location.href =
        '/?preview=1Or0&s=surveyType0&c=offerId1&pubid=pubId2&clickid=clickId3&source=source4';
    }
  };

  // TEMP: removing tm param
  /*
  const hasMissingParams = () => {
    if (urlParams.tm) {
      return false;
    }
    return !urlParams.c || !urlParams.pubid || !urlParams.clickid;
  };
  */

  const initAmplitude = () => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  };

  const listenGlobalErrors = () => {
    window.addEventListener(
      'error',
      ({ message, filename, lineno, colno, error }) => {
        const params = {
          type: 'global',
          message,
          filename,
          lineno,
          colno,
          error,
        };
        amplitude.getInstance().logEvent('error_frontend', params);
      }
    );
  };

  useEffect(() => {
    initAmplitude();
    listenGlobalErrors();
    getOffer();
  }, []);

  return (
    <>
      <Helmet>
        <title>Wayless Quotes</title>
      </Helmet>

      <div className="flex flex-col min-h-screen">
        <AppHeader />
        {/* TEMP: removing tm param */}
        {/*(hasMissingParams() && !loading) || unableShowQuotes ? ( */}
        {unableShowQuotes ? (
          <SurveyError unableShowQuotes={unableShowQuotes} />
        ) : (
          <AppSurvey
            loading={loading}
            onError={() => setUnableShowQuotes(true)}
            urlParams={urlParams}
          />
        )}
        <AppFooter />
      </div>
    </>
  );
};

export default HomePage;
